var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import Vue from "vue";
const EventBus = new Vue();
function countQuotes(str) {
  return str.split('"').length - 1;
}
const SheetClip = {
  parse: function(str) {
    var r, rlen, rows, arr = [], a = 0, c, clen, multiline, last;
    rows = str.split("\n");
    if (rows.length > 1 && rows[rows.length - 1] === "") {
      rows.pop();
    }
    for (r = 0, rlen = rows.length; r < rlen; r += 1) {
      rows[r] = rows[r].split("	");
      for (c = 0, clen = rows[r].length; c < clen; c += 1) {
        if (!arr[a]) {
          arr[a] = [];
        }
        if (multiline && c === 0) {
          last = arr[a].length - 1;
          arr[a][last] = arr[a][last] + "\n" + rows[r][0];
          if (multiline && countQuotes(rows[r][0]) & 1) {
            multiline = false;
            arr[a][last] = arr[a][last].substring(0, arr[a][last].length - 1).replace(/""/g, '"');
          }
        } else {
          if (c === clen - 1 && rows[r][c].indexOf('"') === 0 && countQuotes(rows[r][c]) & 1) {
            arr[a].push(rows[r][c].substring(1).replace(/""/g, '"'));
            multiline = true;
          } else {
            arr[a].push(rows[r][c].replace(/""/g, '"'));
            multiline = false;
          }
        }
      }
      if (!multiline) {
        a += 1;
      }
    }
    return arr;
  },
  stringify: function(arr) {
    var r, rlen, c, clen, str = "", val;
    for (r = 0, rlen = arr.length; r < rlen; r += 1) {
      for (c = 0, clen = arr[r].length; c < clen; c += 1) {
        if (c > 0) {
          str += "	";
        }
        val = arr[r][c];
        if (typeof val === "string") {
          if (val.indexOf("\n") > -1) {
            str += '"' + val.replace(/"/g, '""') + '"';
          } else {
            str += val;
          }
        } else if (val === null || val === void 0) {
          str += "";
        } else {
          str += val;
        }
      }
      str += "\n";
    }
    return str;
  }
};
const getParentTags = function(elem, list) {
  let tagNames = list.toLowerCase().split(",").map(function(e) {
    return e.trim();
  });
  if (tagNames.indexOf(elem.tagName.toLowerCase()) > -1)
    return elem;
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (tagNames.indexOf(elem.tagName.toLowerCase()) > -1)
      return elem;
  }
  return null;
};
const isElement = function(elem) {
  return elem instanceof Element || elem instanceof HTMLDocument;
};
const getElementsByTagNames = function(list, obj) {
  if (!obj)
    obj = document;
  let tagNames = list.split(",");
  let resultArray = new Array();
  for (let i = 0; i < tagNames.length; i++) {
    let tags = obj.getElementsByTagName(tagNames[i].trim());
    for (let j = 0; j < tags.length; j++) {
      resultArray.push(tags[j]);
    }
  }
  let testNode = resultArray[0];
  if (!testNode)
    return [];
  if (testNode.sourceIndex) {
    resultArray.sort(function(a, b) {
      return a.sourceIndex - b.sourceIndex;
    });
  } else if (testNode.compareDocumentPosition) {
    resultArray.sort(function(a, b) {
      return 3 - (a.compareDocumentPosition(b) & 6);
    });
  }
  return resultArray;
};
const on = function() {
  if (document.addEventListener) {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.attachEvent("on" + event, handler);
      }
    };
  }
}();
const off = function() {
  if (document.removeEventListener) {
    return function(element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event) {
        element.detachEvent("on" + event, handler);
      }
    };
  }
}();
const isEmpty = function(value) {
  return value == null || value.length === 0;
};
const isUndef = function(value) {
  return typeof value === "undefined";
};
const addClass = function(elem, name) {
  elem && elem.classList && elem.classList.add(name);
};
const hasClass = function(elem, name) {
  return elem && elem.classList && elem.classList.contains(name);
};
const removeClass = function(elem, name) {
  elem && elem.classList && elem.classList.remove(name);
};
class Actions {
  constructor(obSelector, obApp) {
    __publicField(this, "obApp");
    __publicField(this, "obSelector");
    __publicField(this, "table");
    this.obApp = obApp;
    this.obSelector = obSelector;
    this.table = this.obSelector.table;
  }
  copy(c1, c2) {
    let ar = Array(c2[0] - c1[0] + 1).fill().map(() => Array(c2[1] - c1[1] + 1));
    this.iterateCells(c1, c2, (iy, ix, cell) => {
      ar[iy - c1[0]][ix - c1[1]] = this.obApp.obOptions.getCellFn(cell, [
        iy,
        ix
      ]);
    });
    return ar;
  }
  clear(c1, c2) {
    this.iterateCells(c1, c2, (iy, ix, cell) => {
      if (!this.obSelector.isIgnoredCell(cell)) {
        this.obApp.obOptions.setCellFn(cell, "", [iy, ix]);
      }
    });
  }
  cut(c1, c2) {
    let ar = Array(c2[0] - c1[0] + 1).fill().map(() => Array(c2[1] - c1[1] + 1));
    this.iterateCells(c1, c2, (iy, ix, cell) => {
      ar[iy - c1[0]][ix - c1[1]] = this.obApp.obOptions.getCellFn(cell, [
        iy,
        ix
      ]);
      if (!this.obSelector.isIgnoredCell(cell)) {
        this.obApp.obOptions.setCellFn(cell, "", [iy, ix]);
      }
    });
    return ar;
  }
  iterateCells(c1, c2, callbackFn) {
    const matrix = this.obSelector.sizeMatrix;
    let rows = this.table.getElementsByTagName("tr");
    for (let iy = c1[0]; iy <= c2[0]; iy++) {
      let cells = getElementsByTagNames("td,th", rows[iy]);
      for (let ix = c1[1]; ix <= c2[1]; ix++) {
        if (!(matrix[iy][ix][0] < 0) && !(matrix[iy][ix][1] < 0)) {
          callbackFn(iy, ix, cells[matrix[iy][ix][2]]);
        }
      }
    }
  }
  mergeWithCell(cell, data, coord) {
    let cellVal = this.obApp.obOptions.getCellFn(cell, coord).trim();
    if (cellVal === "")
      cellVal = data;
    else if (data !== "")
      cellVal += this.obApp.obOptions.mergePastingGlue + data;
    this.obApp.obOptions.setCellFn(cell, cellVal, coord);
  }
  paste(data, c1, c2) {
    const matrix = this.obSelector.sizeMatrix;
    const rows = this.table.getElementsByTagName("tr");
    const countR = this.obSelector.countRows;
    const countC = this.obSelector.countCols;
    let maxY = c1[0] + data.length;
    if (maxY > countR)
      maxY = countR;
    if (!isUndef(c2) && maxY > c2[0])
      maxY = c2[0] + 1;
    for (let iy = c1[0]; iy < maxY; iy++) {
      let maxX = c1[1] + data[iy - c1[0]].length;
      if (maxX > countC)
        maxX = countC;
      if (!isUndef(c2) && maxX > c2[1])
        maxX = c2[1] + 1;
      let cellFn;
      for (let ix = c1[1]; ix < maxX; ix++) {
        let y = iy;
        let x = ix;
        if (matrix[iy][ix][1] < 0 || matrix[iy][ix][0] < 0) {
          if (matrix[iy][ix][0] < 0)
            y += matrix[iy][ix][0];
          if (matrix[iy][ix][1] < 0)
            x += matrix[iy][ix][1];
          if (y < c1[0] || x < c1[1])
            continue;
          cellFn = this.mergeWithCell;
        } else {
          cellFn = this.obApp.obOptions.setCellFn;
        }
        let cell = getElementsByTagNames("td,th", rows[y])[matrix[y][x][2]];
        if (!this.obSelector.isIgnoredCell(cell)) {
          cellFn(cell, data[iy - c1[0]][ix - c1[1]], [y, x]);
        }
      }
    }
  }
}
class Buffer2 {
  constructor(container) {
    __publicField(this, "fakeElem");
    __publicField(this, "_onScroll", (e) => this.onScroll(e));
    __publicField(this, "_text");
    this.container = isElement(container) ? container : document.body;
    this.initSelectFake();
    on(window, "scroll", this._onScroll);
  }
  copy(text) {
    try {
      this._text = text;
      this.fakeElem.value = text;
      this.fakeElem.select();
      this.fakeElem.setSelectionRange(0, text.length);
      return document.execCommand("cut");
    } catch (err) {
      return false;
    }
  }
  initSelectFake() {
    const isRTL = document.documentElement.getAttribute("dir") == "rtl";
    this.fakeElem = document.createElement("textarea");
    this.fakeElem.style.fontSize = "12pt";
    this.fakeElem.style.border = "0";
    this.fakeElem.style.padding = "0";
    this.fakeElem.style.margin = "0";
    this.fakeElem.style.position = "absolute";
    this.fakeElem.style[isRTL ? "right" : "left"] = "-9999px";
    this.container.appendChild(this.fakeElem);
  }
  onScroll(e) {
    let yPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.fakeElem.style.top = yPosition + "px";
  }
  paste(callback) {
    this.fakeElem.value = "";
    this.fakeElem.focus();
    let onInput = (e) => {
      this._text = e.target.value;
      this.fakeElem.blur();
      off(this.fakeElem, "input", onInput);
      callback(this._text);
    };
    on(this.fakeElem, "input", onInput);
  }
  get text() {
    return this._text;
  }
  destroy() {
    this.container.removeChild(this.fakeElem);
    this.fakeElem = null;
    off(window, "scroll", this._onScroll);
  }
}
class Selector {
  constructor(table, obEvent, obApp) {
    __publicField(this, "_countCols", 0);
    __publicField(this, "_countRows", 0);
    __publicField(this, "_table");
    __publicField(this, "obApp");
    __publicField(this, "obEvent");
    __publicField(this, "matrix");
    this._table = table;
    this.obApp = obApp;
    this.obEvent = obEvent;
    this.initSizeMatrix();
  }
  deselectCell(cell) {
    removeClass(cell, this.obApp.obOptions.selectClass);
  }
  deselectAll() {
    let length = 0;
    this.goCells((cell, coord) => {
      if (this.isSelectedCell(cell)) {
        this.deselectCell(cell);
        this.obEvent.deselect(cell, coord);
        length++;
      }
    });
    return length;
  }
  getCell(c) {
    if (c[0] >= 0 && c[1] >= 0) {
      if (c[0] < this.countRows && c[1] < this.countCols) {
        if (this.matrix[c[0]][c[1]][0] < 0)
          c[0] += this.matrix[c[0]][c[1]][0];
        if (this.matrix[c[0]][c[1]][1] < 0)
          c[1] += this.matrix[c[0]][c[1]][1];
        let row = this.table.getElementsByTagName("tr")[c[0]];
        return getElementsByTagNames("td,th", row)[this.matrix[c[0]][c[1]][2]];
      }
    }
  }
  get countCols() {
    return this._countCols;
  }
  get countRows() {
    return this._countRows;
  }
  getSelectedRectangleCoords(sc0, sc1) {
    let isSelected = false;
    let c1 = Array(2);
    let c2 = Array(2);
    const scIsAr = Array.isArray(sc0) && Array.isArray(sc1);
    this.goCells((cell, coord) => {
      let [iy, ix] = coord;
      if (scIsAr) {
        if ((sc0[0] !== iy || sc0[1] !== this.matrix[iy][ix][2]) && (sc1[0] !== iy || sc1[1] !== this.matrix[iy][ix][2])) {
          return;
        }
      } else {
        if (!this.isSelectedCell(cell))
          return;
      }
      isSelected = true;
      if (isUndef(c1[0]) || c1[0] > iy)
        c1[0] = iy;
      if (isUndef(c1[1]) || c1[1] > ix)
        c1[1] = ix;
      if (isUndef(c2[0]) || c2[0] < iy)
        c2[0] = iy;
      if (isUndef(c2[1]) || c2[1] < ix)
        c2[1] = ix;
    });
    return isSelected ? [c1, c2] : false;
  }
  get sizeMatrix() {
    return this.matrix;
  }
  getRectangleCoords(c1, c2) {
    let loop = true;
    while (loop) {
      loop = false;
      for (let iy = c1[0]; iy <= c1[0]; iy++) {
        for (let ix = c1[1]; ix <= c2[1]; ix++) {
          if (!isUndef(this.matrix[iy][ix][0]) && this.matrix[iy][ix][0] < 0) {
            c1[0] += this.matrix[iy][ix][0];
            iy += this.matrix[iy][ix][0] - 1;
            loop = true;
            break;
          }
        }
      }
      for (let iy = c2[0]; iy <= c2[0]; iy++) {
        if (c2[0] + 1 == this.countRows)
          continue;
        for (let ix = c1[1]; ix <= c2[1]; ix++) {
          if (!isUndef(this.matrix[iy][ix][0]) && this.matrix[iy + 1][ix][0] < 0) {
            c2[0]++;
            loop = true;
            break;
          }
        }
      }
      for (let iy = c1[0]; iy <= c2[0]; iy++) {
        if (this.matrix[iy][c1[1]][1] < 0) {
          c1[1] += this.matrix[iy][c1[1]][1];
          iy = c1[0] - 1;
          loop = true;
        }
      }
      for (let iy = c1[0]; iy <= c2[0]; iy++) {
        if (c2[1] + 1 == this.countCols)
          continue;
        if (this.matrix[iy][c2[1] + 1][1] < 0) {
          c2[1]++;
          iy = c1[0] - 1;
          loop = true;
        }
      }
    }
    return [c1, c2];
  }
  initSizeMatrix() {
    let rows = this.table.getElementsByTagName("tr");
    this._countRows = rows.length;
    this._countCols = 0;
    for (let row of rows) {
      const colElem = getElementsByTagNames("td, th", row);
      const rowCols = colElem.reduce((pre, curr) => pre + curr.colSpan, 0);
      if (rowCols > this.countCols) {
        this._countCols = rowCols;
      }
    }
    this.matrix = Array(this.countRows).fill().map(() => Array(this.countCols).fill().map(() => Array(2)));
    let rowCrest = new Array(this.countCols).fill(0);
    let iy = 0;
    for (let row of rows) {
      let ix = 0;
      let cells = getElementsByTagNames("td, th", row);
      const crestFn = () => {
        while (ix < this.countCols && rowCrest[ix]) {
          rowCrest[ix]--;
          this.matrix[iy][ix][0] = this.matrix[iy - 1][ix][0] || 0 - 1;
          this.matrix[iy][ix][1] = this.matrix[iy - 1][ix][1];
          ix++;
        }
      };
      for (let itd = 0; itd < cells.length; itd++) {
        const cell = cells[itd];
        let colspan = cell.getAttribute("colspan");
        let rowspan = cell.getAttribute("rowspan");
        if (rowspan > 1)
          this.matrix[iy][ix][0] = 0;
        if (colspan > 1)
          this.matrix[iy][ix][1] = 0;
        crestFn();
        try {
          if (colspan > 1) {
            this.matrix[iy][ix][2] = itd;
            for (let i = 0; i > -colspan; i--) {
              this.matrix[iy][ix][1] = i;
              if (rowspan > 1)
                rowCrest[ix] = rowspan - 1;
              ix++;
            }
          } else {
            this.matrix[iy][ix][2] = itd;
            if (rowspan > 1)
              rowCrest[ix] = rowspan - 1;
            ix++;
          }
        } catch (e) {
          console.error("Error: going beyond the size matrix. " + e);
        }
      }
      crestFn();
      iy++;
    }
  }
  isIgnoredCell(cell) {
    const ppn = cell.parentNode.parentNode;
    return hasClass(cell, this.obApp.obOptions.ignoreClass) || hasClass(cell.parentNode, this.obApp.obOptions.ignoreClass) || this.obApp.obOptions.ignoreThead && ppn.tagName === "THEAD" || this.obApp.obOptions.ignoreTfoot && ppn.tagName === "TFOOT";
  }
  isSelectedCell(cell) {
    return hasClass(cell, this.obApp.obOptions.selectClass);
  }
  select(c1, c2) {
    let isSelected = false;
    if (c1[0] >= this.countRows || c1[1] >= this.countCols || c2[0] < 0 || c2[1] < 0)
      return false;
    if (c1[0] < 0)
      c1[0] = 0;
    if (c1[1] < 0)
      c1[1] = 0;
    if (c2[0] >= this.countRows)
      c2[0] = this.countRows - 1;
    if (c2[1] >= this.countCols)
      c2[1] = this.countCols - 1;
    [c1, c2] = this.getRectangleCoords(c1, c2);
    this.goCells((cell, coord) => {
      let prevState = hasClass(cell, this.obApp.obOptions.selectClass);
      if (coord[0] < c1[0] || coord[0] > c2[0] || coord[1] < c1[1] || coord[1] > c2[1]) {
        if (prevState) {
          this.deselectCell(cell);
          this.obEvent.deselect(cell, coord);
        }
      } else {
        let result = this.selectCell(cell);
        this.obEvent.select(prevState, cell, coord);
        if (!isSelected)
          isSelected = result;
      }
    });
    return isSelected;
  }
  selectAll() {
    let length = 0;
    this.goCells((cell, coord) => {
      let prevState = hasClass(cell, this.obApp.obOptions.selectClass);
      let result = this.selectCell(cell);
      if (result) {
        this.obEvent.select(prevState, cell, coord);
        length++;
      }
    });
    return length;
  }
  selectCell(cell) {
    if (this.obApp.obOptions.selectIgnoreClass || !this.isIgnoredCell(cell)) {
      addClass(cell, this.obApp.obOptions.selectClass);
      return true;
    }
    return false;
  }
  goCells(fn) {
    let rows = this.table.getElementsByTagName("tr");
    if (rows.length !== this.countRows) {
      this.initSizeMatrix();
    }
    for (let iy = 0; iy < this.countRows; iy++) {
      let cells = getElementsByTagNames("td, th", rows[iy]);
      for (let ix = 0; ix < this.countCols; ix++) {
        if (!(this.matrix[iy][ix][0] < 0) && !(this.matrix[iy][ix][1] < 0)) {
          fn(cells[this.matrix[iy][ix][2]], [iy, ix]);
        }
      }
    }
  }
  get table() {
    return this._table;
  }
}
class Event {
  constructor() {
    __publicField(this, "startSelect");
    __publicField(this, "finishSelect");
    __publicField(this, "select");
    __publicField(this, "deselect");
  }
}
class Table {
  constructor(table, obSelector, obEvent, obApp) {
    __publicField(this, "isMouseDown", false);
    __publicField(this, "obApp");
    __publicField(this, "obSelector");
    __publicField(this, "obEvent");
    __publicField(this, "table");
    __publicField(this, "coord0");
    __publicField(this, "_isMouse", false);
    __publicField(this, "_onMouseOver", (e) => this.onMouseOver(e));
    __publicField(this, "_onMouseDown", (e) => this.onMouseDown(e));
    __publicField(this, "_onMouseEnter", (e) => this.onMouseEnter(e));
    __publicField(this, "_onMouseLeave", (e) => this.onMouseLeave(e));
    __publicField(this, "_onMouseUp", (e) => this.onMouseUp(e));
    __publicField(this, "_onOutTableClick", (e) => this.onOutTableClick(e));
    if (isElement(table) && table.tagName === "TABLE") {
      this.table = table;
      this.obApp = obApp;
      this.obSelector = obSelector;
      this.obEvent = obEvent;
      addClass(this.table, this.obApp.obOptions.tableClass);
      this.addEvents();
    } else {
      throw new Error("\u041Codule must be initialized to Table");
    }
  }
  addEvents() {
    on(this.table, "mouseover", this._onMouseOver);
    on(this.table, "mousedown", this._onMouseDown);
    on(this.table, "mouseenter", this._onMouseEnter);
    on(this.table, "mouseleave", this._onMouseLeave);
    on(document.body, "mouseup", this._onMouseUp);
    on(this.table.ownerDocument, "click", this._onOutTableClick);
  }
  removeEvents() {
    off(this.table, "mouseover", this._onMouseOver);
    off(this.table, "mousedown", this._onMouseDown);
    off(this.table, "mouseenter", this._onMouseEnter);
    off(this.table, "mouseleave", this._onMouseLeave);
    off(document.body, "mouseup", this._onMouseUp);
    off(this.table.ownerDocument, "click", this._onOutTableClick);
  }
  get isMouse() {
    return this._isMouse;
  }
  isRightMouseBtn(e) {
    let isRightMB;
    e = e || window.event;
    if ("which" in e)
      isRightMB = e.which == 3;
    else if ("button" in e)
      isRightMB = e.button == 2;
    return isRightMB;
  }
  onMouseDown(e) {
    if (this.isIgnoreMouseDown)
      return;
    if (this.obApp.obOptions.mouseBlockSelection)
      e.preventDefault();
    if (this.isRightMouseBtn(e))
      return true;
    let cell = getParentTags(e.target, "td,th");
    if (cell === null)
      return;
    this.isMouseDown = true;
    this.obSelector.deselectAll();
    this.obSelector.selectCell(cell);
    this.obEvent.startSelect(e, cell);
    this.coord0 = [cell.parentNode.rowIndex, cell.cellIndex];
  }
  onMouseOver(e) {
    let cell = getParentTags(e.target, "td,th");
    if (cell === null)
      return;
    if (!this.isMouseDown) {
      this.isIgnoreMouseDown = this.obSelector.isSelectedCell(cell);
      return;
    }
    let coords = this.obSelector.getSelectedRectangleCoords(this.coord0, [
      cell.parentNode.rowIndex,
      cell.cellIndex
    ]);
    if (coords !== false)
      this.obSelector.select(coords[0], coords[1]);
  }
  onMouseEnter() {
    this._isMouse = true;
  }
  onMouseLeave() {
    this._isMouse = false;
  }
  onMouseUp(e) {
    if (this.isMouseDown) {
      this.obEvent.finishSelect(e);
      this.isIgnoreMouseDown = true;
    }
    this.isMouseDown = false;
  }
  onOutTableClick() {
    this.isMouseDown = false;
    if (this.obApp.obOptions.deselectOutTableClick && !this.isMouse) {
      this.obSelector.deselectAll();
    }
  }
  destroy() {
    this.removeEvents();
  }
}
let _gOptions = {
  deselectOutTableClick: true,
  enableChanging: false,
  enableHotkeys: true,
  getCellFn: function(cell, coord) {
    return cell.innerText;
  },
  ignoreClass: "tcs-ignore",
  ignoreTfoot: false,
  ignoreThead: false,
  mergePastingGlue: " ",
  mouseBlockSelection: true,
  onSelect: function(prevState, cell, coord) {
  },
  onDeselect: function(cell, coord) {
  },
  onStartSelect: function(e, cell) {
  },
  onFinishSelect: function(e) {
  },
  selectIgnoreClass: true,
  selectClass: "tcs-select",
  setCellFn: function(cell, data, coord) {
    cell.innerText = data;
  },
  tableClass: "tcs"
};
class TableCellSelector {
  constructor(table, options, buffer) {
    __publicField(this, "enableChanging");
    __publicField(this, "enableHotkeys");
    __publicField(this, "obActions");
    __publicField(this, "obBuffer");
    __publicField(this, "obSelector");
    __publicField(this, "obEvent");
    __publicField(this, "obTable");
    __publicField(this, "obOptions");
    __publicField(this, "_onKeyDown", (e) => this.onKeyDown(e));
    if (typeof options === "object")
      this.obOptions = __spreadValues(__spreadValues({}, _gOptions), options);
    this.enableChanging = this.obOptions.enableChanging;
    this.enableHotkeys = this.obOptions.enableHotkeys;
    this.obEvent = new Event();
    this.obEvent.startSelect = this.obOptions.onStartSelect;
    this.obEvent.finishSelect = this.obOptions.onFinishSelect;
    this.obEvent.select = this.obOptions.onSelect;
    this.obEvent.deselect = this.obOptions.onDeselect;
    this.obSelector = new Selector(table, this.obEvent, this);
    this.obTable = new Table(table, this.obSelector, this.obEvent, this);
    this.obActions = new Actions(this.obSelector, this);
    this.obBuffer = buffer;
    on(document.body, "keydown", this._onKeyDown);
  }
  static get Buffer() {
    return Buffer2;
  }
  clear(c1, c2) {
    if (isUndef(c1)) {
      let coords = this.obSelector.getSelectedRectangleCoords();
      if (coords === false)
        return false;
      [c1, c2] = coords;
    }
    if (!isUndef(c2))
      [c1, c2] = this.normalizeCoords(c1, c2);
    else
      c2 = c1 = this.normalizeCoords(c1);
    this.obActions.clear(c1, c2);
    return true;
  }
  copy(c1, c2) {
    if (isUndef(c1)) {
      let coords = this.obSelector.getSelectedRectangleCoords();
      if (coords === false)
        return false;
      [c1, c2] = coords;
    }
    if (isUndef(c2))
      c2 = c1 = this.normalizeCoords(c1);
    else
      [c1, c2] = this.normalizeCoords(c1, c2);
    const data = this.obActions.copy(c1, c2);
    if (this.obBuffer instanceof Buffer2 && data !== false) {
      let str = SheetClip.stringify(data);
      this.obBuffer.copy(str);
    }
    return data;
  }
  cut(c1, c2) {
    if (isUndef(c1)) {
      let coords = this.obSelector.getSelectedRectangleCoords();
      if (isEmpty(coords))
        return false;
      [c1, c2] = coords;
    }
    if (isUndef(c2))
      c2 = c1 = this.normalizeCoords(c1);
    else
      [c1, c2] = this.normalizeCoords(c1, c2);
    const data = this.obActions.cut(c1, c2);
    if (this.obBuffer instanceof Buffer2 && data !== false) {
      let str = SheetClip.stringify(data);
      this.obBuffer.copy(str);
    }
    return data;
  }
  deselect() {
    return this.obSelector.deselectAll();
  }
  getCoords() {
    return this.obSelector.getSelectedRectangleCoords();
  }
  initSizeMatrix() {
    this.obSelector.initSizeMatrix();
  }
  normalizeCoords(c1, c2) {
    c1[0] = parseInt(c1[0]) || 0;
    c1[1] = parseInt(c1[1]) || 0;
    if (isUndef(c2)) {
      return c1;
    } else {
      c2[0] = parseInt(c2[0]) || 0;
      c2[1] = parseInt(c2[1]) || 0;
      let temp;
      if (c1[0] > c2[0]) {
        temp = c2[0];
        c2[0] = c1[0];
        c1[0] = temp;
      }
      if (c1[1] > c2[1]) {
        temp = c2[1];
        c2[1] = c1[1];
        c1[1] = temp;
      }
      return [c1, c2];
    }
  }
  onKeyDown(e) {
    if (!this.enableHotkeys)
      return;
    e = e || window.event;
    var key = e.which || e.keyCode;
    var ctrl = e.ctrlKey ? e.ctrlKey : key === 17;
    if (this.obTable.isMouse && ctrl) {
      switch (key) {
        case 65:
          e.preventDefault();
          this.selectAll();
          break;
        case 67:
          this.copy();
          break;
        case 86:
          if (!this.enableChanging)
            break;
          if (this.obBuffer instanceof Buffer2) {
            this.obBuffer.paste((str) => {
              this.paste(SheetClip.parse(str));
            });
          }
          break;
        case 88:
          if (!this.enableChanging)
            break;
          this.cut();
          break;
        case 46:
        case 8:
          if (!this.enableChanging)
            break;
          this.clear();
          break;
      }
    }
  }
  set onStartSelect(fn) {
    this.obEvent.startSelect = fn;
  }
  set onSelect(fn) {
    this.obEvent.select = fn;
  }
  set onFinishSelect(fn) {
    this.obEvent.finishSelect = fn;
  }
  paste(data, c1, c2) {
    if (isUndef(c1)) {
      let coords = this.obSelector.getSelectedRectangleCoords();
      if (coords === false)
        return false;
      c1 = this.normalizeCoords(coords[0]);
    } else if (!isUndef(c2)) {
      [c1, c2] = this.normalizeCoords(c1, c2);
      [c1, c2] = this.obSelector.getRectangleCoords(c1, c2);
    }
    this.obActions.paste(data, c1, c2);
    return true;
  }
  select(c1, c2) {
    this.obSelector.deselectAll();
    if (isUndef(c2)) {
      c1 = this.normalizeCoords(c1);
    } else {
      [c1, c2] = this.normalizeCoords(c1, c2);
    }
    return this.obSelector.select(c1, c2);
  }
  selectAll() {
    return this.obSelector.selectAll();
  }
  destroy() {
    off(document.body, "keydown", this._onKeyDown);
    this.deselect();
    this.obTable.destroy();
    delete this.obActions, this.obBuffer, this.obSelector, this.obTable;
  }
}
global.TableCellSelector = TableCellSelector;
export { EventBus as E, SheetClip as S, TableCellSelector as T };
